.fifthSection {
  display: flex;
  padding: 100px 0px 120px 0px;
  flex-direction: column;
  align-items: center;
  gap: 300px;
  width: 100%;
  background: var(--Main-Primary-Varient, #3e404e);
  margin: auto;
  object-fit: fill;
  background-image: url('/Home/HomeBg6.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -2px;
  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
.carouselContainer {
  margin-top: 100px;
}

.cardsContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 160px;
  cursor: pointer;
}

.activeCard {
  width: 280px;
  // height: 606px;
}

.activeImage {
  width: 280px;
  height: 586px;
  margin-top: 15px;
}

.activeTitle {
  text-align: center;
}

.activeDescription {
  text-align: center;
  padding: 0px 20px;
}

.nonActiveDescription {
  text-align: center;
  margin-top: 10px;
  line-height: 2rem;
}

.nonActiveCard {
  width: 180px;
  // height: 606px;
}

.nonActiveImage {
  width: 180px;
  height: 384px;
}

.bulletPoints {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: -250px;
}

/* Fifth Section Mobile */
.containerFifthMobileSection {
  background-color: #272935;
  background-image: url('/Home/HomeMobileBg6.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0 80px 0;
  margin-top: -1px;
}

.carouselStyles {
  flex-grow: 1;
  min-height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselContainer {
  margin-top: 20px;
}

.activeCardContainer {
  display: flex;
  //   width: 85%;
  width: 310px;
  //   height: 502px;
  padding-top: 10px;
  //   padding-left: 10px;
  flex-direction: column;
  align-items: center;
}

.nonActiveCardContainer {
  display: flex;
  width: 300px;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  opacity: 0.5;
}

.activeImageMobile {
  width: 100%;
  height: 80%;
  margin-top: 30px;
  @media (max-width: 380px) {
    width: 100%;
    height: 80%;
  }
}

.nonActiveImageMobile {
  width: 100%;
  height: 60%;
  margin-top: 40px;
  @media (max-width: 380px) {
    width: 100%;
    height: 60%;
  }
}

.activeTitleMobile {
  text-align: center;
}

.activeDescriptionMobile {
  text-align: center;
}

.nonActiveDescriptionMobile {
  text-align: center;
  margin-top: 10px;
}

.planetMobileCard {
  display: flex;
  // width: 300px;
  width: 55vw;
  height: 100%;
  padding-top: 29px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  border-radius: 29.419px;
  z-index: 1000;
  border-radius: 29.419px;
  @media (max-width: 380px) {
    height: 100%;
  }
}

.planetCardMobileDeselect {
  display: flex;
  // width: 300px;
  width: 55vw;
  height: 100%;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  height: 100%;
  opacity: 0.5;
}

.widthPlanetMobileCard {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planetMobileImage {
  display: flex;
  width: 130%;
  height: 340px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 100px;
  @media (max-width: 380px) {
    margin-top: 20px;
  }
}
