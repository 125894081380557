.outerContainer {
  display: flex;
  padding: 100px 0px 120px 0px;
  flex-direction: column;
  align-items: center;
  // gap: 300px;
  width: 100%;
  background: var(--Main-Primary-Varient, #3e404e);
  background-image: url('/Home/HomeBg3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  object-fit: fill;
  margin-bottom: 0px;

  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
.outerContainerMobile {
  display: flex;
  padding: 10px 0px 60px 0px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--Main-Primary-Varient, #272935);
  margin-top: -2px;
  background-image: url('/Home/HomeMobileBg3.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // margin: auto;
  // object-fit: fill;

  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  @media (max-width: 380px) {
    padding-right: 2px;
  }
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  margin-bottom: 20px;
}

.cardsContainerMobile {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.card {
  margin-top: 50px;
  border-radius: 16px;
  // background: var(--neutral-glass-1-add-blur, #272935);
  background: linear-gradient(180deg, #3e404e 0%, #272935 100%);

  display: flex;
  width: 522px;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  max-width: 522px;
  max-height: 209px;
  margin-bottom: -50px;
  padding: 62px;
  @media (max-width: 835px) {
    max-width: 350px;
    width: 100%;
    margin-bottom: -30px;
    max-height: 370px;
    gap: 16px;
    padding: 24px 20px;
  }
}

.cardWord {
  color: var(--Main-Primary-Varient2, #9198b0);
  text-align: center;
}

.illustrationContainer {
  display: flex;
  // grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  @media (max-width: 835px) {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
}

.illustrationSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 36px;
  border-radius: 24px;
  opacity: 0.6;
  background: var(--Main-Primary-Varient, #272935);
  box-shadow: 5px 5px 20px 0px rgba(7, 12, 17, 0.9);
  width: 335px;
  height: 408px;
}

.illustrationSectionActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 36px;
  border-radius: 24px;
  background: var(--Main-Primary, #3e404e);
  box-shadow: 5px 5px 20px 0px rgba(7, 12, 17, 0.9);
}

.illustrationActive {
  width: 520px;
  // width: 100%;
  // width: calc(100% - 20px); /* Ensure it fits within the padding */
  height: 361px;
  max-width: 522px;
  border-radius: 20px;
  // background: var(--main-primary-varient-250, rgba(145, 152, 176, 0.5));
  @media (max-width: 835px) {
    max-width: 361px;
    height: 250px;
  }
  @media (max-width: 380px) {
    // max-width: 340px;
    // height: 300px;
    max-width: 341px;
    height: 235px;
  }
}

.illustration {
  width: 335px;
  // width: 100%;
  // width: calc(100% - 20px); /* Ensure it fits within the padding */
  height: 230px;
  // max-width: 522px;
  border-radius: 20px;
  // background: var(--main-primary-varient-250, rgba(145, 152, 176, 0.5));
  @media (max-width: 835px) {
    max-width: 361px;
    height: 250px;
  }
  @media (max-width: 380px) {
    // max-width: 340px;
    // height: 300px;
    max-width: 341px;
    height: 235px;
  }
}

.illustrationMobile {
  width: 360px;
  height: 100%;
}

ul.customList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

ul.customList li {
  margin-top: 8px;
  line-height: 2.2rem;
  padding-left: 1em; /* Adds indentation */
  text-indent: -1em; /* Ensures text starts after the bullet */
  color: #9198b0;
  position: relative;
  width: 1px;
  height: 1px;
}

ul.customList li::before {
  content: '• '; /* Bullet point */
  position: absolute;
  left: 0;
}

.nav .link {
  position: relative;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav .link.active {
  color: #f2f3f7;
}

.nav .link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 50%;
  height: 2px;
  background-color: #f2f3f7;
  margin-left: 10px;
}
