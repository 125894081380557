// @use 'src/styles/colors' as colors;

.row {
  display: flex;
  justify-content: space-between;
}

.card {
  // width: 155px;
  // height: 132px;
  background-color: rgba(100, 100, 100, 0.7);
  border-radius: 16px;
  padding: 20px 16px 20px 16px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
}

.cardTitle {
  font-size: 20px;
  margin-top: -10px;
}

.cardDescription {
  font-size: 10px;
  line-height: 11px;
  margin-top: 5px;
}

.video {
  position: relative;
  height: 35vh;
  width: 100%;
  // overflow: hidden;
}

.titleColumn {
  display: flex;

  max-width: 842px;
  padding: 42px;
  flex-direction: column;
  // align-items: stretch;
  gap: 24px;
  border-radius: 16px;
  background: var(--neutral-glass-1-add-blur, rgba(255, 255, 255, 0.12));
  align-items: center;
  /* Glassmorphism/BG Blur 1 */
  backdrop-filter: blur(10px);
  margin: auto;
  // margin: 20px 0px;
  margin-bottom: 131px;
  text-align: center;

  @media (max-width: 835px) {
    max-width: 350px;
    text-align: left;
  }
}

.subheading {
  color: var(--Main-Primary-Varient2, #9198b0);
  line-height: 1.5;
}

.indicatorContainer {
  padding: 5px 0px 0px 20px;
  display: flex;
  gap: 5px;
}
.indicator {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  transition: width 200ms ease;
  position: relative;
  background-color: rgba(164, 165, 173, 1);
}
.indicatorActive {
  width: 8px !important;
  height: 8px !important;
  width: 42px !important;
  border-radius: 24px;
  transition: width 200ms ease;
  position: relative;
  background-color: rgba(26, 89, 222, 1);
}

.cardContainer {
  padding-top: 400px;
}

.carouselContainer {
  // padding-bottom: 30px;
  margin-top: -20px;
  padding-left: 10px;
  // background-color: rgba(62, 64, 78, 1);
  // z-index: 3000;
}

.section5 {
  display: flex;
  padding: 100px 0px 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Main-Primary-Varient, #272935);
  text-align: center;
}

.section5p2 {
  display: flex;
  padding: 42px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  // align-self: stretch;
  max-width: 836px;

  @media (min-width: 835px) {
    padding: 42px 60px;
  }
}

.button {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  margin-top: 42px;
  padding: 12px 24px;

  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Main-Secondary, #4178fa);
  font-size: 18px;
  font-weight: 700;
}

.titleColumnMobile {
  display: flex;
  padding: 42px;
  flex-direction: column;
  // align-items: stretch;
  gap: 24px;
  border-radius: 16px;
  background: var(--neutral-glass-1-add-blur, rgba(255, 255, 255, 0.12));
  align-items: center;
  /* Glassmorphism/BG Blur 1 */
  backdrop-filter: blur(10px);
  // max-width: 842px;
  // margin: auto;
  margin: 00px 30px;
  margin-bottom: 131px;
  text-align: center;
}

.video {
  position: relative;
  height: 35vh;
  width: 100%;
  // overflow: hidden;
}

.subheadingMobile {
  line-height: 1.5;
  padding: '20px 20px';
  text-align: 'center';
  margin: 'auto';
  // max-width: '800px';
}

.section4 {
  display: flex;
  padding: 100px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Main-Primary-Varient, #3e404e);
  text-align: center;
}

.section4p2 {
  display: flex;
  // padding: 42px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 42px;
  // align-self: stretch;
  max-width: 836px;

  @media (max-width: 835px) {
    max-width: 350px;
  }
}

.sec4Vid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 836px;
  max-height: 510px;
  border-radius: 16px;

  @media (max-width: 835px) {
    max-width: 350px;
    max-height: 210px;
  }
}

.planetsSection {
  display: flex;
  padding: 100px 0px 120px 0px;
  flex-direction: column;
  align-items: center;
  gap: 300px;
  width: 100%;
  background: var(--Main-Primary-Varient, #3e404e);
  margin: auto;
  object-fit: fill;

  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

.section2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0 10px; /* Add padding to left and right */
}

.card {
  margin-top: 50px;
  border-radius: 16px;
  background: var(--neutral-glass-1-add-blur, rgba(255, 255, 255, 0.12));
  display: flex;
  width: calc(100% - 20px); /* Ensure it fits within the padding */
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  padding: 62px;
  @media (max-width: 835px) {
    max-width: 350px;
    max-height: 370px;
    padding: 62px 42px;
  }
}

.cardWord {
  color: var(--Main-Primary-Varient2, #9198b0);
  text-align: center;
}

.section3 {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: center;
  margin: auto;
}

.section3Content {
  display: flex;
  max-width: 1060px;
  max-height: 445px;
  padding: 142px 60px;
  align-items: center;
  margin: auto;
}

.section3Word {
  max-width: 440px;
}
.section3Planet {
  display: flex;
  // padding: 72.486px 61px 52.514px 58px;
  flex-direction: column;
  // justify-content: flex-end;
  align-items: center;
  // position: absolute;
  margin: auto;
  max-width: 441.2px;
  max-height: 443.5px;
}

.section3Mobile {
  position: relative;
  padding: 42px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  // background-image: url('/Bg1.png');
  // background-size: cover;
  // background-position: relative;
  // background-repeat: no-repeat;
  background-color: #000;
}

.section3PlanetMobile {
  max-width: 300.253px;
  max-height: 300.253px;
  display: inline-flex;
  // padding: 48.356px 40.694px 35.032px 38.692px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  // flex-shrink: 0;
}

.collapsibleCardsContainerMobile {
  gap: 0px;
  width: 100%;
  cursor: pointer;
}

.secondSectionCardMobile {
  margin: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0px 0px;
  overflow: hidden;
  height: 400px;
  width: 290px;
  position: relative;
}

.secondSectionImageMobile {
  width: 85%;
  height: 245px;
  // padding: 0px 32px;
  border-radius: 8px;
  margin-bottom: 0px;
  object-fit: cover;
  background: var(--main-primary-varient-55, rgba(39, 41, 53, 0.5));
}

.collapsibleCardMobile {
  margin: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 0px 0px;
  overflow: hidden;
  height: 400px;
  width: 290px;
  position: relative;
}

.collapsibleImageMobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--main-primary-varient-55, rgba(39, 41, 53, 0.5));
}

.cardNumberMobile {
  padding-left: 15px;
  margin-top: -80px;
}

.cardTitleOpenMobile {
  // padding-left: 15px;
  // margin-top: -145px;
}

.cardDescription2Mobile {
  // padding-left: 15px;
  // padding-right: 15px;
  // margin-top: -95px;
}

.collapsibleCardsContainer {
  display: flex;
  gap: 0px;
  cursor: pointer;
}

.collapsibleCard {
  margin: 15px;
  // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 0px 0px;
  overflow: hidden;
  height: 500px;
  width: 515px;
  border-radius: 16px;
  box-shadow: 5px 0px 20px 0px rgba(7, 12, 17, 0.9);
  // z-index: 100;
}

.collapsibleCardUnfocused {
  margin: 15px;
  // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 0px 0px;
  overflow: hidden;
  height: 500px;
  width: 515px;
  border-radius: 16px;
  box-shadow: 5px 0px 20px 0px rgba(7, 12, 17, 0.9);
  margin-left: -500px;
}

.content {
  transition: width 1s ease;
  overflow: hidden;
}

.collapsibleImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: fadeIn 0.5s linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.gradientContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.gradientOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  pointer-events: none;
}

.collapsibleImageUnfocused {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 5px 0px 20px 0px rgba(7, 12, 17, 0.9);
  background: linear-gradient(
      0deg,
      rgba(39, 41, 53, 0.5) 0%,
      rgba(39, 41, 53, 0.5) 100%
    )
    lightgray 50% / cover no-repeat;
}

.cardTitle2 {
  padding-left: 15px;
  margin-top: -75px;
}

.cardTitleOpen {
  padding-left: 15px;
  margin-top: -165px;
}

.cardDescription2 {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 5px;
}

.collapsibleCardSection {
  display: flex;
  padding: 100px 0px 120px 0px;
  flex-direction: column;
  align-items: center;
  gap: 300px;
  width: 100%;
  background: #272935;
  margin: auto;
  object-fit: fill;
  background-image: url('/Home/HomeBg2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: none;
    gap: 0px;
    background-image: url('/Home/HomeMobileBg2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.fourthSection {
  display: flex;
  padding: 80px 0px 0px 0px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 300px;
  width: 100%;
  background: var(--Main-Primary-Varient, #272935);
  background-image: url('/Home/HomeBg4.png');
  background-size: cover;
  background-position: relative;
  background-repeat: no-repeat;
  margin: auto;
  object-fit: fill;
  margin-top: -1px;

  @media (max-width: 835px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-image: url('/Home/HomeMobileBg4.png');
    background-size: cover;
    background-position: relative;
    background-repeat: no-repeat;
    margin-top: -1px;
  }
  @media (max-width: 380px) {
    padding-right: 2px;
  }
}

.buttonsContainer {
  position: relative;
  text-align: start;
  padding: 24px 40px;
  // background-color: #272935;
  // background: linear-gradient(180deg, #3e404e 0%, #272935 100%);
  background: linear-gradient(180deg, #3e404e 0%, #272935 100%);

  backdrop-filter: blur(20px);

  border: 0;
  border-radius: 16px;
  width: 400px;
  cursor: pointer;
  @media (max-width: 835px) {
    width: 350px;
    padding: 20px 30px;
  }
}

.selectedImageContainer {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  // gap: 0.75rem;
  // margin: 0.75rem 0;
  border-radius: 16px;
  width: 500px;
  // height: 100%;
  // background-color: var(--neutral-glass-1-add-blur, rgba(255, 255, 255, 0.12));
  @media (max-width: 835px) {
    width: 350px;
  }
}

.selectedImage {
  width: 450px;
  height: auto;
  margin-top: -60px;
  border-radius: 16px;
  object-fit: fill;
  // background: var(--main-primary-varient-55, rgba(39, 41, 53, 0.5));
  @media (max-width: 835px) {
    width: 75%;
    height: 75%;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.carouselStyles {
  flex-grow: 1;
  min-height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
