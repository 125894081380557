.containerPlanetSection {
  background-image: url('/Home/HomeBg5Planets.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 30%;
  position: relative;
  display: flex;
  // flex-direction: row;
  flex-direction: column;
  padding: 100px 0 390px 0;
  overflow: hidden;
  margin-top: -3px;
  align-items: center;
}

.planetSize {
  width: 56px;
  height: 56px;
}

.planetCard {
  display: flex;
  width: 367.742px;
  padding-top: 35.548px;
  flex-direction: column;
  align-items: center;
  border-radius: 29.419px;
  overflow: hidden;
  height: 450px;
  background-image: url('/Frame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.widthPlanetCard {
  width: 306.452px;
  height: 500px;
}

.planetCardDiselect {
  margin-top: 28px;
  display: flex;
  width: 367.742px;
  height: 400px;
  border-radius: 29.419px;
  padding: 35.548px 4.904px 0px 0px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  background-image: url('/Frame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.planetImage {
  opacity: 1;
  animation: moveIn 0.3s linear;
}

.transitionAnimation {
  opacity: 1;
  animation: moveIn 0.3s linear;
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;

    transform: translateX(0px);
  }

  // 0% {
  //   transform: translateX(100px);
  // }
}

.planetImageDiselect {
  display: flex;
  width: 365.29px;
  height: 365.29px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

/* Planet Mobile */
.containerPlanetMobileSection {
  background-color: #272935;
  background-image: url('/Home/HomeMobileBg5Planets.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 100px 0 80px 0;
  margin-top: -1px;
}

.planetMobileCard {
  display: flex;
  width: 300px;
  height: 502px;
  padding-top: 29px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  background-image: url('/Frame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 29.419px;
  z-index: 1000;
  border-radius: 29.419px;
  background: linear-gradient(
      0deg,
      rgba(39, 41, 53, 0.8) 0%,
      rgba(39, 41, 53, 0.8) 100%
    ),
    url('/Frame.png') lightgray 50% / cover no-repeat, #fff;

  /* Shadows/Form Shadow (Light) */
  box-shadow: 0px 0px 10px 0px rgba(160, 167, 212, 0.2);
  @media (max-width: 380px) {
    height: 432px;
  }
}

.planetCardMobileDeselect {
  display: flex;
  width: 300px;
  height: 450px;
  margin-top: 40px;
  padding-top: 29px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  overflow: hidden;
  background-image: url('/Frame.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.widthPlanetMobileCard {
  width: 250px;
}

.planetMobileImage {
  display: flex;
  width: 260px;
  height: 260px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: 50px;
  @media (max-width: 380px) {
    margin-top: 20px;
  }
}

.carouselStyles {
  flex-grow: 1;
  min-height: 10vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselContainer {
  margin-top: 20px;
}

.activePlanetNameContainer {
  border-radius: 100px;
  background: var(--Main-Primary, #3e404e);
  display: flex;
  width: 210px;
  height: 75px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-left: -25px;
}
