@use 'styles/common/colors';

.container {
  background-image: url('/Home/HomeHeroBg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 100vh;
  margin-top: -90px;
  width: 100%;
}

.containerMobile {
  background-image: url('/Home/HomeHeroMobileBg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 100vh;
  margin-top: -90px;
  width: 100%;
  padding: 30px 0px;

  @media (max-width: 380px) {
    margin-top: 5px;
  }
}

.widthContainer {
  display: flex;
  width: 1036px;
  flex-direction: row;
  // align-items: center;
  gap: 24px;
  padding: 100px 30px;
  justify-content: center;
  align-items: center;
  text-align: start;
  @media (max-width: 835px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

.buttonsContainer {
  display: flex;
  margin-top: 20px;
  @media (max-width: 835px) {
    justify-content: center;
    margin-top: 40px;
  }
}

.downloadButtonContainer {
  width: 159px;
  height: 52px;
}

.downloadImage {
  cursor: pointer;
}

.homeHeroImagePhone {
  width: 350px;
  height: 100%;
  margin-top: 130px;
  margin-left: 150px;
  @media (max-width: 835px) {
    width: 230px;
    height: 100%;
    margin-top: 30px;
    align-items: center;
    margin-left: 0px;
  }
}

.homeHeroMascot {
  width: 550px;
  height: 100%;
  margin-top: 130px;
  margin-left: -480px;
  animation: bounceIn 2s infinite 2s;
  @media (max-width: 835px) {
    width: 330px;
    height: 100%;
    margin-top: -370px;
    align-items: center;
    margin-left: 0px;
  }
}

@keyframes bounceIn {
  0%,
  50%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-30px);
  }
}

.homeHeroImageGif {
  width: 780px;
  height: 100%;
  // margin-left: -30px;
  // height: 768px;
  margin-top: 130px;
  margin-left: -600px;
  @media (max-width: 835px) {
    width: 330px;
    height: 100%;
    margin-top: -350px;
    align-items: center;
    margin-left: 0px;
  }
}

.homeHeroImage {
  width: 600px;
  height: 100%;
  // margin-left: -30px;
  // height: 768px;
  margin-top: 130px;
  margin-left: -100px;
  @media (max-width: 835px) {
    width: 330px;
    height: 100%;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
  }
}

.homeHeroVideo {
  width: 600px;
  height: 100%;
  background: transparent;
  // margin-left: -30px;
  // height: 768px;
  margin-top: 130px;
  margin-left: -100px;
  @media (max-width: 835px) {
    width: 330px;
    height: 100%;
    margin-top: 0px;
    align-items: center;
    margin-left: 0px;
  }
}

.downloadContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: center;
  margin: auto;
}

.downloadContent {
  display: flex;
  max-width: 836px;
  height: 100%;
  // padding: 0px 60px;
  align-items: center;
  gap: 24px;
  margin: auto;
  text-align: center;
}

.backgroundWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Solid background color */
  z-index: -1; /* Ensure the background wrapper is behind other content */
  align-items: center;
  margin: auto;
}

.Button2 {
  display: flex !important;
  height: 40px !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin-top: 42px !important;
  align-items: center !important;
  padding: 12px 24px !important;
  gap: 8px !important;
  border-radius: 8px !important;
  background-color: var(--Main-Secondary, #4178fa) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.downloadContainer2 {
  display: flex;
  // width: 100%;
  padding: 60px;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--Main-Primary, #3e404e);
  background: var(--Main-Primary-Varient, #272935);
  max-width: 838px;
  /* Shadows/Card Shadow (Dark) */
  box-shadow: 0px 4px 20px 0px rgba(12, 11, 11, 0.8);
  //   max-width: 100%;
  margin: auto;
  // margin-bottom: 131px;
  text-align: center;
}

.icon {
  color: #50c7ba;
  border: 1px solid #4178fa;
  border-radius: 52px;
  padding: 20px;
  margin-bottom: 20px;
}

.iconContainer {
  align-items: center;
  gap: 14px;
  align-self: stretch;
}

.iconSection {
  max-width: 300px;
}

.topContainer {
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  // height: 500px;
  padding-inline: 4%;
  padding-top: 120px;
  padding-bottom: 120px;
  color: white;
  margin-top: -1px;
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: -webkit-fill-available;
}

.headingMobile {
  line-height: 52px;
  text-align: center;
  // padding: 0px 20px;
  margin: auto;
}

.image {
  max-width: 838px;
  height: 424.311px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  // max-width: 836px;
  // max-height: 510px;
  border-radius: 16px;

  @media (max-width: 835px) {
    max-width: 350px;
    max-height: 210px;
  }
}

.cursorAnimate {
  animation: 1s blink step-end infinite;
  color: #fff;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #000;
  }
}
